<template>
	<div class="bg-light-blue text-dark-blue min-h-screen">
		<router-view />
	</div>
</template>

<script>
import '@/css/tailwind.css';

export default {
	name: 'App',
};
</script>
